import React from 'react'
import { Icon } from 'ui'
import { useTranslation } from 'react-i18next'
import { MetricsCategory, MetricsName, trackEvent } from '../../utils/amplitude'

const ONBOARDING_MESSAGE = 'setOnboarding'

const Step3: React.FC = () => {
  const { t } = useTranslation()
  const extensionId = localStorage.getItem('extensionId')

  const handleButtonClick = (url: string, metricName: MetricsName) => {
    extensionId &&
      chrome.runtime.sendMessage(extensionId, { action: ONBOARDING_MESSAGE })
    window.open(url, '_blank', 'noopener')
    trackEvent(MetricsCategory.GrowthInteracted, { name: metricName })
  }

  return (
    <div className="flex flex-col items-center justify-center mb-8">
      <h2 className="text-[40px] font-semibold text-blue-correcto-800 mb-20">
        {t('Pruébalo en un clic')}
      </h2>
      <div className="flex gap-14">
        <AppButton
          imgSrc="/assets/onboardingGmail.svg"
          altText="gmail"
          text={`${t('Abrir en')} Gmail`}
          onClick={() =>
            handleButtonClick(
              'https://mail.google.com/mail/u/0/#inbox?compose=new',
              MetricsName.GmailClicked,
            )
          }
        />
        <AppButton
          imgSrc="/assets/onboardingWhatsapp.svg"
          altText="whatsapp"
          text={`${t('Abrir en')} Whatsapp`}
          onClick={() =>
            handleButtonClick(
              'https://web.whatsapp.com/',
              MetricsName.WhatsappClicked,
            )
          }
        />
      </div>
      <p className="text-xs font-light mt-8 inline-flex items-center gap-3">
        <Icon icon="security" size="md" />
        {t('Y recuerda Correcto no guarda ninguna informacion personal')}
      </p>
    </div>
  )
}

interface AppButtonProps {
  imgSrc: string
  altText: string
  text: string
  onClick: () => void
}

const AppButton: React.FC<AppButtonProps> = ({
  imgSrc,
  altText,
  text,
  onClick,
}) => (
  <button
    style={{
      borderRadius: '20px',
      boxShadow: '0px 4px 8px 0px rgba(204, 124, 0, 0.10)',
    }}
    className="w-[264px] h-[162px] p-[22px] flex flex-col justify-between items-center bg-white font-normal
    text-blue-correcto-800 focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
    onClick={onClick}
  >
    <img src={imgSrc} alt={altText} className="h-auto" />
    <p>{text}</p>
  </button>
)

export default Step3
