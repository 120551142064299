import { useEffect, type FC, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, Typography, Button, useModal } from 'ui'
import { useNavigate, useParams } from 'react-router-dom'
import { useUser } from '../../../../contexts'
import { trackUserAction } from '../../../../utils/amplitude'

export const PremiumSuggestionsDetectedInfoBanner: FC = () => {
  const { t } = useTranslation()
  const modalManager = useModal()
  const userManager = useUser()
  const navigate = useNavigate()
  const premiumUser = userManager.user?.is_premium
  const { projectUuid } = useParams()
  const [
    isAdvancedSuggestionsAcknowledged,
    setIsAdvancedSuggestionsAcknowledged,
  ] = useState<boolean>()

  const refreshAcknowdledgement = useCallback(() => {
    if (projectUuid) {
      const isAcknowledged = localStorage.getItem(`ack[${projectUuid}]`)
      setIsAdvancedSuggestionsAcknowledged(Boolean(isAcknowledged))
    }
  }, [projectUuid])

  useEffect(() => {
    refreshAcknowdledgement()
  })

  const showPremiumInfoModal = () => {
    modalManager.openModal({
      body: (
        <div className="flex flex-col items-center gap-2">
          <Typography
            className="text-black font-normal"
            component="p"
            variant="body2"
          >
            {t('Son sugerencias')}
            <Typography className="px-1 font-semibold" variant="body2">
              {t('generadas por IA')}
            </Typography>
            {t('que')}
            <Typography className="px-1 font-semibold" variant="body2">
              {t('mejoran la calidad y la variedad')}
            </Typography>
            {t(
              'de tus documentos. Gracias a estas sugerencias puedes conseguir:',
            )}
          </Typography>
          <div className="pl-8">
            <ul className="text-left list-disc">
              <li>{t('Expresar una idea de manera diferente.')}</li>
              <li>{t('Enriquecer tu redacción.')}</li>
              <li>{t('Evitar repeticiones excesivas.')}</li>
              <li>{t('Un estilo más claro y diverso.')}</li>
            </ul>
          </div>
          <div className="flex p-4 bg-neutral-100 gap-4">
            <div>
              <Icon icon="bulb" size="sm" />
            </div>
            <div className="flex flex-col gap-2">
              <Typography
                className="font-semibold"
                component="p"
                variant="small"
              >
                {t('Selecciona la frase que quieras mejorar')}
              </Typography>
              <Typography component="p" variant="small">
                {t(
                  'O deja que nosotros te demos las mejores opciones de reescritura. Tú decides.',
                )}
              </Typography>
            </div>
          </div>
          <Typography
            className="text-black font-normal"
            component="p"
            variant="small"
          >
            {premiumUser ? (
              <>
                {t('Por ser un usuario Premium, tienes un')}
                <Typography className="px-1 font-semibold" variant="small">
                  {t('uso ilimitado')}
                </Typography>
                {t('de las sugerencias avanzadas.')}
              </>
            ) : (
              <>
                {t('Te ofrecemos')}
                <Typography className="px-1 font-semibold" variant="small">
                  {t('100 sugerencias avanzadas')}
                </Typography>
                {t(
                  'para que tu escritura llegue a otro nivel. (Siempre que el párrafo tenga un mínimo de 150 caracteres)',
                )}
              </>
            )}
          </Typography>
        </div>
      ),
      title: t('Sugerencias de reescritura'),
      type: 'graphic',
      primaryAction: premiumUser
        ? t('Aceptar')
        : t('Quiero optimizar mis textos'),
      primaryActionType: premiumUser ? 'primary' : 'premium',
      primaryIcon: premiumUser ? undefined : 'crown',
      secondaryAction: premiumUser ? undefined : t('Cancelar'),
      onPrimaryClick: () => {
        userManager.user?.is_premium
          ? modalManager.closeModal()
          : navigate('/premium')
      },
      ilustration: 'group',
      textAlign: 'left',
    })
  }

  const acknowledgeAdvancedSuggestionsInfo = () => {
    if (projectUuid) {
      localStorage.setItem(`ack[${projectUuid}]`, 'true')
      setIsAdvancedSuggestionsAcknowledged(true)
    }
  }

  if (isAdvancedSuggestionsAcknowledged) return null

  return (
    <div className="bg-blue-correcto-100 flex p-4">
      <div className="pr-4 text-blue-correcto-800">
        <Icon icon="file-certificate" size="md" />
      </div>
      <div className="flex flex-col">
        <Typography
          className="font-bold text-blue-correcto-800 pb-2"
          variant="small"
        >
          {t('Hemos detectado sugerencias avanzadas')}
        </Typography>
        <Typography className="pb-2" variant="small">
          {t(
            'Estas sugerencias de reescritura generadas por IA mejoran la calidad y la variedad de tus documentos.',
          )}
        </Typography>
        <div className="flex gap-4">
          <Button
            className="w-fit"
            label={t('Quiero saber más')}
            onClick={() => {
              showPremiumInfoModal()
              trackUserAction({
                name: 'Rephrase info I want to know more',
              })
            }}
            size="customSmall"
            variant="secondary"
          />
          <Button
            className="w-fit"
            label={t('Cerrar')}
            onClick={() => {
              acknowledgeAdvancedSuggestionsInfo()
              trackUserAction({
                name: 'Rephrase info close',
              })
            }}
            size="small"
            variant="tertiary"
          />
        </div>
      </div>
    </div>
  )
}
